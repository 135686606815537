import React, { useState, useEffect } from "react";
import { RedirectStore } from "@components/content/RedirectStore";
import { getLangFromUrl, useTranslations } from "src/i18n/utils";
import { getAnalytics, logEvent } from "firebase/analytics";

interface GoToAppButtonProps {
  buttonText?: string;
}

const GoToAppButton: React.FC<GoToAppButtonProps> = ({ buttonText }) => {
  const [showModal, setShowModal] = useState(false);
  const [os, setOs] = useState<string>("");
  const url = new URL(window.location.href);
  const lang = getLangFromUrl(url);
  const t = useTranslations(lang);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const analytics = getAnalytics();

    if (/android/i.test(userAgent)) {
      setOs("Android");
      try {
        logEvent(analytics, "redirect_to_store", { platform: "Android" });
      } catch (error) {
        console.error("Analytics tracking blocked", error);
      }
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      setOs("iOS");
      try {
        logEvent(analytics, "redirect_to_store", { platform: "iOS" });
      } catch (error) {
        console.error("Analytics tracking blocked", error);
      }
    } else {
      setOs("PC");
      try {
        logEvent(analytics, "redirect_to_store", { platform: "PC" });
      } catch (error) {
        console.error("Analytics tracking blocked", error);
      }
    }
  }, []);

  const handleOpenModalOrRedirect = () => {
    if (os === "PC") {
      setShowModal(true);
    } else {
      handleMobileRedirect();
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleMobileRedirect = () => {
    if (os === "Android") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=pawfolio.app.jausme&pcampaignid=web_share";
    } else if (os === "iOS") {
      window.location.href =
        "https://apps.apple.com/co/app/pawfolio/id6511193179?l=en-GB";
    }
  };

  return (
    <div>
      <button
        className={`relative font-normal inline-flex items-center justify-center leading-5 no-underline space-x-1 text-white bg-primary-500 h-12 px-5 py-3 text-sm rounded-xl hover:bg-primary-600 hover:shadow-xl hover:shadow-primary-500/20 tw-accessibility transition-all duration-300 w-full ${
          !buttonText && "md:w-[240px] lg:w-[150px]"
        }`}
        onClick={handleOpenModalOrRedirect}
      >
        {buttonText || t("downloadButton.text")}
      </button>

      {showModal && os === "PC" && (
        <RedirectStore isModal={true} onClose={handleCloseModal} />
      )}
    </div>
  );
};

export default GoToAppButton;
